import React from 'react';
import SEO from '../components/seo';
import ArtworkSingle from '../components/ArtworkSingle';
import ArtworkDouble from '../components/ArtworkDouble';
import VideoPlayer from '../components/VideoPlayer';
import Reveal from '../components/Reveal';
import Link from '../components/Link';
import { ArrowRight } from '../components/SVG';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import sanityImage from '../util/sanityImage';
import isEmpty from 'lodash/isEmpty';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import useHover from '../util/useHover';
import scroll from '../util/scrollHelper';
import dateHelper from '../util/dateHelper';

const Exhibition = ({ pageContext, location }) => {
	const {
		seo = {},
		title,
		artist,
		exhibitionLocation,
		exhibitionLocationOther,
		hero,
		startDate,
		endDate,
		subtitle,
		virtualTourCode,
		pressReleaseTitle,
		pressReleaseImg,
		pressReleaseFile,
		pressReleaseContent,
		video,
		installation = [],
		recommendedProjectsTitle,
		recommendedProjects = [],
		artworkTitle,
		artwork = [],
		inquire = {},
	} = pageContext;

	const metaTitle = seo?.metaTitle || 'Library Street Collective';
	const openGraphTitle = seo?.openGraphTitle || 'Library Street Collective';
	const twitterTitle = seo?.twitterTitle || 'Library Street Collective';
	const currentPath = location.pathname.replace(/^\//, '');

	const [hoverRef, isHovered] = useHover();

	const [sliderRef, slider] = useKeenSlider({
		slidesPerView: 1,
		loop: true,
		spacing: 50,
	});

	const heroSection = hero && (
		<Reveal>
			<section className="reveal__slide reveal__delay--1">
				<picture
					ref={hoverRef}
					className="db x bb--black artwork--full-container"
				>
					<source
						srcSet={`${hero.url}?w=2000&auto=format&q=100`}
						media="(min-width: 1200px)"
					/>
					<source
						srcSet={`${hero.url}?w=1200&auto=format&q=100`}
						media="(min-width: 1000px)"
					/>
					<source
						srcSet={`${hero.url}?w=800&auto=format`}
						media="(min-width: 600px)"
					/>
					<img
						alt={`${title} — ${artist}`}
						className={cx('db artwork--full', {
							hovered: isHovered,
						})}
						src={`${hero.url}?w=600&auto=format`}
					/>
				</picture>
				<aside className="mt2 grid-container contained">
					<h1 className="sans--24 sans--36--md sans--48--lg">
						{artist}
					</h1>

					{title && <p className="sans--18 sans--24--md">{title}</p>}
					{subtitle && (
						<p className="sans--14 sans--18--md sans--24--lg">
							{subtitle}
						</p>
					)}
					{exhibitionLocationOther && (
						<p className="sans--14 sans--18--md sans--24--lg">
							Location: {exhibitionLocationOther}
						</p>
					)}
					{exhibitionLocation && !exhibitionLocationOther && (
						<p className="sans--14 sans--18--md sans--24--lg">
							{exhibitionLocation === 'downtown-detroit' && 'Location: 1260 Library Street, Detroit'}
    							{exhibitionLocation === 'the-shepherd' && 'Location: the Shepherd'}
						</p>
					)}

					<p className="sans--14 sans--18--md sans--24--lg">
						{dateHelper(startDate)} — {dateHelper(endDate)}
					</p>
				</aside>
			</section>
		</Reveal>
	);

	const showPressRelease =
		!isEmpty(pressReleaseContent) && !isEmpty(pressReleaseImg);

	const pressReleaseSection = showPressRelease && (
		<Reveal id="press_release">
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<div className="grid-container contained">
					<p className="sans--24 sans--36--md sans--48--lg mb5 mb5--md mb0--lg">
						{pressReleaseTitle || 'Press Release'}
					</p>

					<div className="row">
						<div className="col c5--lg psy--lg top pt10--lg">
							<picture>
								<source
									srcSet={`${pressReleaseImg.artwork.url}?w=2000&auto=format&q=100`}
									media="(min-width: 1200px)"
								/>
								<source
									srcSet={`${pressReleaseImg.artwork.url}?w=1200&auto=format&q=100`}
									media="(min-width: 1000px)"
								/>
								<source
									srcSet={`${pressReleaseImg.artwork.url}?w=800&auto=format`}
									media="(min-width: 600px)"
								/>
								<img
									alt={`${title} — ${artist}`}
									className="db x b--black"
									src={`${pressReleaseImg.artwork.url}?w=600&auto=format`}
								/>
							</picture>

							{pressReleaseImg.title && (
								<p className="sans--14 sans--18--lg mt2">
									{pressReleaseImg.title}
								</p>
							)}
							{pressReleaseImg.description && (
								<p className="sans--14 sans--18--lg mt2 rich-text line-break">
									<BlockContent
										blocks={pressReleaseImg.description}
										serializers={richText}
									/>
								</p>
							)}
						</div>
						<div className="col c1--lg show--lg pt5--md pt10--lg" />
						<div className="col c6--lg pt5 pt10--lg">
							<div className=" sans--18 sans--24--md line-break rich-text ">
								<BlockContent
									blocks={pressReleaseContent}
									serializers={richText}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Reveal>
	);

	const videoSection = video && <VideoPlayer {...video} />;

	const installationSection = installation && installation.length > 0 && (
		<Reveal id="installation">
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<p className="sans--24 sans--36--md sans--48--lg grid-container contained mb5 mb10--md">
					Install Images
				</p>

				{installation.length > 1 ? (
					<div className="grid-container contained pr">
						<div
							ref={sliderRef}
							className="project__carousel keen-slider df"
						>
							{installation.map(image => (
								<picture
									key={image._key}
									className="keen-slider__slide"
								>
									<source
										srcSet={sanityImage(image.url, {
											w: 2400,
										})}
										media="(min-width: 1800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 2000,
										})}
										media="(min-width: 1600px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1600,
										})}
										media="(min-width: 1200px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1200,
										})}
										media="(min-width: 800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1000,
										})}
										media="(min-width: 600px)"
									/>
									<img
										alt={image.alt || 'Installation Image'}
										className="db x y object-fit--cover b--black"
										src={sanityImage(image.url, {
											w: 800,
										})}
									/>
								</picture>
							))}
						</div>
						{slider && (
							<React.Fragment>
								<button
									className="slider__button slider__button--prev pa"
									onClick={e =>
										e.stopPropagation() || slider.prev()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider flip" />
								</button>
								<button
									className="slider__button slider__button--next pa"
									onClick={e =>
										e.stopPropagation() || slider.next()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider" />
								</button>
							</React.Fragment>
						)}
					</div>
				) : (
					<picture>
						<source
							srcSet={`${installation[0].url}?w=2000&auto=format&q=100`}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={`${installation[0].url}?w=1200&auto=format&q=100`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${installation[0].url}?w=800&auto=format`}
							media="(min-width: 600px)"
						/>
						<img
							alt={`${title} — ${artist}`}
							className="db"
							src={`${installation[0].url}?w=600&auto=format`}
						/>
					</picture>
				)}
			</div>
		</Reveal>
	);

	const virtualTourSection = virtualTourCode && (
		<Reveal id="virtual_tour">
			<section className="reveal__slide reveal__delay--1 mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg grid-container contained">
				<div
					className="x y exhibition__virtual-tour pr b--black"
					dangerouslySetInnerHTML={{
						__html: virtualTourCode,
					}}
				/>
			</section>
		</Reveal>
	);

	const artworkSection = artwork && artwork.length > 0 && (
		<Reveal id="artwork">
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md grid-container contained">
					{artworkTitle || 'Artwork Images'}
				</p>
				{artwork.map((section, index) => {
					const { _type } = section;

					return _type == 'artworkImageSingle' ? (
						<ArtworkSingle
							index={index}
							artist={artist}
							exhibition
							{...section}
						/>
					) : (
						<ArtworkDouble
							index={index}
							title={title}
							artist={artist}
							exhibition
							{...section}
						/>
					);
				})}
			</div>
		</Reveal>
	);

	const relatedWorkSection = recommendedProjects &&
		recommendedProjects.length > 0 && (
			<Reveal>
				<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
					<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
						{recommendedProjectsTitle || 'Recommended Work'}
					</p>
					<ul className="mt2 mt5--md mb2 mb5--md row df fw">
						{recommendedProjects.map((work, index) => {
							const {
								_key,
								_type,
								title,
								subtitle,
								artist,
								slug,
								hero,
								heroImage,
								image,
								startDate,
								endDate,
							} = work;

							let destination;

							switch (_type) {
								case 'exhibition':
									destination = `/exhibitions/${slug}`;
									break;
								case 'artist':
									destination = `/artists/${slug}`;
									break;
								case 'site':
									destination = `/exhibitions/site-${slug}`;
									break;
								case 'anatomy':
									destination = `/exhibitions/anatomy-${slug}`;
									break;
								case 'queue':
									destination = `/exhibitions/queue-${slug}`;
									break;
								case 'alliance':
									destination = `/alliance/${slug}`;
									break;
								case 'project':
									destination = `/projects/${slug}`;
									break;
								case 'page':
									destination = `/${pageSlug}`;
									break;

								default:
									break;
							}

							const picture = hero || heroImage || image;

							return (
								<li
									key={_key}
									className={cx('col c6--md c4--lg', {
										mt5: index > 0,
										'mt0--md': index < 2,
										'mt0--lg': index < 3,
										'mt10--md': index > 2,
									})}
								>
									<Link url={destination}>
										<picture className="db b--black image--tile x">
											<source
												srcSet={`${picture.url}?w=800&auto=format`}
												media="(min-width: 600px)"
											/>
											<img
												src={picture.url}
												className="db featured-link--image y"
												alt={
													title ||
													artist ||
													'Library Street Collective'
												}
											/>
										</picture>
										<p className="mt2 sans--18 sans--24--md">
											{_type === 'alliance' &&
												`Alliance: ${title}`}
											{_type === 'anatomy' &&
												`Anatomy: ${title}`}
											{_type === 'site' &&
												`SITE: ${title}`}
											{_type === 'artist' && artist}
											{_type === 'exhibition' && artist}
											{_type === 'project' && artist}
										</p>
										{(_type === 'project' ||
											_type === 'exhibition') && (
											<p className="sans--14 sans--18--md">
												{title}
											</p>
										)}
										{subtitle && (
											<p className="sans--14 sans--18--md">
												{subtitle}
											</p>
										)}
										{endDate && startDate && (
											<p className="sans--14 sans--18--lg">
												{dateHelper(startDate)} —{' '}
												{dateHelper(endDate)}
											</p>
										)}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</Reveal>
		);

	const mailtoLink =
		inquire &&
		`mailto:info@lscgallery.com?subject=${inquire?.subject}&body=${
			!!inquire.body ? inquire.body : ''
		}`;

	const inquireSection = inquire && (
		<div
			id="inquire"
			className="mt5 mb5 mt10--md mb10--md mt20--lg mb20--lg grid-container contained"
		>
			<a href={mailtoLink} className=" tc x db tile--large">
				<span className="sans--24 sans--36--md sans--48--lg">
					{(inquire && inquire.linkText) ||
						`Inquire about available works in ${title}`}
				</span>
			</a>
		</div>
	);

	const exhibitionNavigation = (
		<nav className="psy--md projects__navigation bb--black bg--white z10">
			<section className="df fw grid-container contained ">
				{pressReleaseSection && (
					<button
						className="sans--14 sans--18--lg mr2 mr4--lg mt1 mb1"
						onClick={() => scroll('press_release')}
					>
						Press Release
					</button>
				)}
				{virtualTourSection && (
					<button
						className="sans--14 sans--18--lg mr2 mr4--lg mt1 mb1"
						onClick={() => scroll('virtual_tour')}
					>
						{currentPath === 'exhibitions/skalar-detroit' ? 'Reservation' : 'Virtual Tour'}
						<span className={currentPath}></span>
					</button>
				)}
				{video && (
					<button
						className="sans--14 sans--18--lg mr2 mr4--lg mt1 mb1"
						onClick={() => scroll('video')}
					>
						Video
					</button>
				)}
				{installationSection && (
					<button
						className="sans--14 sans--18--lg mr2 mr4--lg mt1 mb1"
						onClick={() => scroll('installation')}
					>
						Installation Images
					</button>
				)}
				{artworkSection && (
					<button
						className="sans--14 sans--18--lg mr2 mr4--lg mt1 mb1"
						onClick={() => scroll('artwork')}
					>
						Artwork Images
					</button>
				)}
				{inquireSection && (
					<button
						className="sans--14 sans--18--lg mr2 mr4--lg mt1 mb1"
						onClick={() => scroll('inquire')}
					>
						Inquire
					</button>
				)}
			</section>
		</nav>
	);

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			{exhibitionNavigation}
			{heroSection}
			{pressReleaseSection}
			{virtualTourSection}
			{videoSection}
			{installationSection}
			{artworkSection}
			{relatedWorkSection}
			{inquireSection}
		</React.Fragment>
	);
};

export default Exhibition;
